import React from "react"
import Layout from "@/components/layout"
import BolsaForm from "@/components/PesquisaDeSatisfacaoForm"

const PesquisaDeSatisfacaoPage = () => {
  return (
    <Layout>
      <BolsaForm />
    </Layout>
  );
};

export default PesquisaDeSatisfacaoPage;
