/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoSerios from '../assets/images/lp/logotipo_serios.svg'

import { 
  sucessContent,
  logoImg, 
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <section css={sucessContent} style={{ background: '#033770' }}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}><img src={LogoSerios} alt="Colégio Seriös" title="Colégio Seriös" /></div>
            <div style={{ textAlign: 'center' }}>
              <iframe src="https://forms.monday.com/forms/embed/b2493d9bf37c11c15a2c09293e1c542c?r=use1" width="100%" height="5600" style={{ border: 'none' }} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;
